import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import { Title } from "./title"
import { Navbar } from "./navbar"
import { Footer } from "./footer"
import GoogleFont from "./GoogleFont"

const Eyecatch = ({ title, eyecatchURL, eyecatchSmartphoneURL }) => (
  <div>
    <img
      src={eyecatchURL}
      width="1200"
      height="600"
      layout="responsive"
      alt={`eyecatch for ${title}`}
      className="mb4 mx-auto block"
      media="(min-width: 416px)"
    ></img>
    <img
      src={eyecatchSmartphoneURL}
      width="1200"
      height="600"
      layout="responsive"
      alt={`eyecatch for ${title}`}
      className="mb4 mx-auto block"
      media="(max-width: 415px)"
    ></img>
  </div>
)

const BackToIndex = ({ slug, techniquesURL }) => {
  if (slug !== "index") {
    return (
      <section>
        <Link to={techniquesURL}>目次ページに戻る</Link>
      </section>
    )
  } else {
    return null
  }
}

export default ({
  slug,
  title,
  description,
  eyecatchURL,
  eyecatchSmartphoneURL,
  techniquesURL,
  children,
}) => (
  <div>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link
        rel="preload"
        href={eyecatchURL}
        as="image"
        media="(min-width: 416px)"
      />
      <link
        rel="preload"
        href={eyecatchSmartphoneURL}
        as="image"
        media="(max-width: 415px)"
      />
      <GoogleFont />
    </Helmet>
    <Navbar />
    <Eyecatch
      title={title}
      eyecatchURL={eyecatchURL}
      eyecatchSmartphoneURL={eyecatchSmartphoneURL}
    />
    <main id="content" role="main" className="px3">
      <article>
        <Title title={title} />
        <section className="mb4">{children}</section>
        <BackToIndex slug={slug} techniquesURL={techniquesURL} />
      </article>
    </main>
    <Footer />
  </div>
)
