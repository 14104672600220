import React from "react"
import { graphql } from "gatsby"
import { siteURL, techniquesURL, siteTitle } from "../config/config"
import JsonLD from "../components/jsonld"
import Ogp, { OGP_ARTICLE } from "../components/ogp"
import Container from "../components/container"

export default ({ data }) => {
  const post = data.contentfulPost
  const title = post.title + " | " + siteTitle
  const description = post.description.description
  const eyecatchURL = post.eyecatch.file.url
  const eyecatchSmartphoneURL = post.eyecatchSmartphone.file.url
  const techniquesTop = siteURL + techniquesURL
  const canonicalURL =
    post.slug === "index" ? techniquesTop : techniquesTop + post.slug
  const breadcrumbList = [
    {
      name: "Top",
      url: siteURL,
    },
    {
      name: "テクニック集",
      url: techniquesTop,
    },
  ]

  if (post.slug !== "index") {
    breadcrumbList.push({
      name: post.title,
      url: techniquesTop + post.slug,
    })
  }

  return (
    <Container
      slug={post.slug}
      title={title}
      description={description}
      eyecatchURL={eyecatchURL}
      eyecatchSmartphoneURL={eyecatchSmartphoneURL}
      techniquesURL={"/" + techniquesURL}
    >
      <JsonLD
        headline={title}
        description={description}
        articleImageURL={eyecatchSmartphoneURL}
        datePublished={post.createdAt}
        dateModified={post.updatedAt}
        canonicalURL={canonicalURL}
        breadcrumbList={breadcrumbList}
      />
      <Ogp
        type={OGP_ARTICLE}
        title={title}
        description={description}
        url={canonicalURL}
        imageURL={eyecatchSmartphoneURL}
      />

      <div
        dangerouslySetInnerHTML={{
          __html: post.content.childMarkdownRemark.html,
        }}
      />
    </Container>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      description {
        description
      }
      eyecatch {
        file {
          url
        }
      }
      eyecatchSmartphone {
        file {
          url
        }
      }
      createdAt
      updatedAt
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
